import React, {Component} from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import {Helmet} from "react-helmet";

export default class Privacy extends Component {
  render() {
    return (
      <Index>
        <Seo title="Privacy Statement" />
        <Helmet>
          <script type="text/javascript">
            {`
            (function() {
              const initPrivacy = function() {
                // Hide unnecessary elements.
                var elementsToRemove = document.querySelectorAll('.hide-after-load');
                for (var i = 0; i < elementsToRemove.length; i++) {
                  elementsToRemove[i].remove();
                }
                // Initialize OneTrust privacy page.
                settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9";
                OneTrust.NoticeApi.Initialized.then(function() {
                  OneTrust.NoticeApi.LoadNotices(["https://appds8093.blob.core.windows.net/67a7111b-7745-4111-a6d1-5ebef90b16c0/privacy-notices/f137f8d8-382c-475a-90ea-d5f0c1d7c185.json"]);
                });
              };
              var s = document.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = 'https://appds8093.blob.core.windows.net/privacy-notice-scripts/otnotice-1.0.min.js';
              s.setAttribute('id', 'otprivacy-notice-script');
              s.onreadystatechange = function() {
                if (this.readyState === 'complete' || this.readyState === 'loaded') {
                  initPrivacy();
                }
              };
              s.onload = initPrivacy;
              document.getElementsByTagName('head')[0].appendChild(s);
            })();
            `}
          </script>
        </Helmet>
        <Container>
          <h1 className="text-blue hide-after-load" aria-hidden="true">Privacy Statement</h1>
          <p className="lead text-blue hide-after-load" aria-hidden="true">
            Loading, please wait...
          </p>
          <div className="otnotice" id="otnotice-f137f8d8-382c-475a-90ea-d5f0c1d7c185">&nbsp;</div>
          <p>
            <a href="https://privacyportal.onetrust.com/webform/67a7111b-7745-4111-a6d1-5ebef90b16c0/82aaf34f-7a7b-4e33-a01a-688f74ce4f9a" target="_blank" rel="noreferrer" className="button">Manage My Personal Information</a>&nbsp; &nbsp; &nbsp;
            <a href="https://site.fffenterprises.com/ic-preference-center.html" target="_blank" rel="noreferrer" className="button">Manage My Communication Preferences</a>
          </p>
          <p>&nbsp;</p>
        </Container>
      </Index>
    );
  }
}
